import { Injectable } from '@angular/core';
import { CoreApiService } from './core.service';
import { Map } from 'src/app/domain/models/map.model';
import { QueryOptions } from '../types';
import { concatMap, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '..';

@Injectable({
  providedIn: 'root',
})
export class MapApiService extends CoreApiService<Map> {
  constructor(http: HttpClient, firebaseApp: FirebaseApp, dataService: DataService<Map>) {
    super(http, firebaseApp, dataService);
  }
  protected override dbPath: string = 'maps';
  protected getServiceIdentifier(): string {
    return 'MapApiService';
  }

  override getAll(queryOptions: QueryOptions): Observable<Map[]> {
    const userId = this.auth.currentUser?.uid;

    if (!userId) throw new Error('User not found');

    queryOptions.where = [];
    return super.getAll(queryOptions);
  }

  moveEntity(params: {
    mapId: string;
    sourceId: string;
    destinationId: string;
    callback?: () => void;
  }): Observable<Map> {
    return this.get(params.mapId).pipe(
      concatMap((currentMap) => {
        if (!currentMap) throw new Error('Map not found');

        if (currentMap.lock) throw new Error('Map is locked');
        const sourceNode = currentMap.nodes.find((node) => node.id === params.sourceId);
        const destinationNode = currentMap.nodes.find((node) => node.id === params.destinationId);
        if (!sourceNode || !destinationNode) throw new Error('Source or destination node not found');

        if (sourceNode.id === destinationNode.id) return of(currentMap);
        if (sourceNode.parentId === destinationNode.id) return of(currentMap);

        sourceNode.parentId = destinationNode.id;
        const childrenOfSourceNode = currentMap.nodes.filter((node) => node.parentId === sourceNode.id);
        if (childrenOfSourceNode.length > 0) {
          childrenOfSourceNode.forEach((child) => {
            delete child.parentId;
          });
        }

        return this.update(params.mapId, currentMap);
      }),
    );
  }

  lock(mapId: string, lock: boolean): Observable<Map> {
    return this.get(mapId).pipe(
      switchMap((group) => {
        if (!group) throw new Error('Group not found');
        return this.update(mapId, { ...group, lock });
      }),
    );
  }

  generatePDF(mapId: string, nodeId: string) {
    return this.http.post(`${this.url}/generator/generate-pdf`, {
      mapId,
      nodeId,
      userId: this.userId,
    });
  }

  exportMap(mapId: string): Observable<string> {
    return this.http.post<string>(`${this.url}/${this.dbPath}/export-map`, {
      mapId,
    });
  }
}
