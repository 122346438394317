import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Inject, isDevMode } from '@angular/core';
import {
  map,
  Observable,
  switchMap,
  catchError,
  from,
  of,
  shareReplay,
  BehaviorSubject,
  defer,
  tap,
  ReplaySubject,
  finalize,
  interval,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseApp } from '@angular/fire/app';
import { DataService } from '../helpers';
import { QueryOptions } from '../types';
import { CoreModel } from 'src/app/domain';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@angular/fire/auth';
import { Network } from '@capacitor/network';

export abstract class CoreApiService<T extends CoreModel> {
  protected readonly serviceIdentifier!: string;
  protected readonly auth = inject(Auth);
  private platform: string = 'web';

  constructor(
    protected http: HttpClient,
    @Inject(FirebaseApp) protected firebaseApp: FirebaseApp,
    protected dataService: DataService<T>,
  ) {
    from(Capacitor.isNativePlatform() ? Capacitor.getPlatform() : Promise.resolve('web')).subscribe({
      next: (platform) => {
        this.platform = platform;
      },
      error: (err) => {
        console.error('Error detecting platform:', err);
        this.platform = 'web'; // Fallback to 'web' on error
      },
    });
  }

  protected get userId() {
    return this.auth.currentUser?.uid;
  }

  url = environment.backendUrl;

  protected abstract readonly dbPath: string;
  protected abstract getServiceIdentifier(): string;

  protected getDbPath(): string {
    const path = this.dbPath;
    this.logInfo(`Getting dbPath: ${path}`);
    if (!path) {
      this.logError(`dbPath not set`);
      throw new Error(`dbPath not set for ${this.serviceIdentifier}`);
    }
    return path;
  }

  create(data: T & { [x: string]: any }): Observable<T> {
    const id = uuidv4();
    const newData = {
      ...data,
      createdAt: new Date().toISOString(),
      id: id,
      userId: data['userId'] || this.userId,
    } as T;

    const endpoint = `${this.url}/${this.getDbPath()}`;

    return this.getOnlineStatus().pipe(
      switchMap((online) => {
        if (online) {
          return this.http.post<T>(endpoint, newData).pipe(
            switchMap((response) =>
              this.dataService.setLocalData(this.getDbPath(), id, response).pipe(map(() => response)),
            ),
            catchError((error) => {
              console.log('Error', error);
              return this.dataService.saveTransaction(this.getDbPath(), id, newData, 'POST', endpoint).pipe(
                map((result) => result as T), // Type assertion here
              );
            }),
          );
        } else {
          return this.dataService.saveTransaction(this.getDbPath(), id, newData, 'POST', endpoint).pipe(
            map((result) => {
              if (result === null) {
                throw new Error('Failed to save transaction');
              }
              return result as T; // Type assertion here
            }),
          );
        }
      }),
    );
  }

  update(id: string, data: any): Observable<T> {
    (data as any)['editedAt'] = new Date().toISOString();
    const endpoint = `${this.url}/${this.getDbPath()}/${id}`;

    if ((data as any)['mapId']) {
      const mapId = typeof (data as any)['mapId'] === 'string' ? (data as any)['mapId'] : (data as any)['mapId'].id;
    }

    if ((data as any)['userId']) {
      const userId = typeof (data as any)['userId'] === 'string' ? (data as any)['userId'] : (data as any)['userId'].id;
    }

    console.log('Call update:', endpoint);

    return this.getOnlineStatus().pipe(
      tap({
        next: (online) => {},
        complete: () => console.log('Observable completed'),
      }),
      finalize(() => console.log('getOnlineStatus unsubscribed')),
      switchMap((online) => {
        if (online) {
          return this.http.put<T>(endpoint, data).pipe(
            switchMap((response) =>
              this.dataService.setLocalData(this.getDbPath(), id, response).pipe(map(() => response)),
            ),
            catchError((error) => {
              console.log('Error updating entity:', error);
              return this.dataService.saveTransaction(this.getDbPath(), id, data, 'PUT', endpoint).pipe(
                map((result) => {
                  if (result === null) {
                    throw new Error('Failed to save update transaction');
                  }
                  return result as T;
                }),
              );
            }),
          );
        } else {
          return this.dataService.saveTransaction(this.getDbPath(), id, data, 'PUT', endpoint).pipe(
            map((result) => {
              if (result === null) {
                throw new Error('Failed to save update transaction');
              }
              return result as T;
            }),
          );
        }
      }),
    );
  }

  delete(id: string): Observable<T | null> {
    const endpoint = `${this.url}/${this.getDbPath()}/${id}`;

    return this.getOnlineStatus().pipe(
      switchMap((online) => {
        if (online) {
          return this.get(id).pipe(
            switchMap((entity) => {
              return this.http.delete<T>(endpoint).pipe(
                switchMap(() => from(this.dataService.removeLocalData(this.getDbPath(), id)).pipe(map(() => entity))),
                catchError((error) => {
                  return this.dataService.saveTransaction(this.getDbPath(), id, null, 'DELETE', endpoint).pipe(
                    map(() => {
                      throw error;
                    }),
                  );
                }),
              );
            }),
          );
        } else {
          return this.dataService.saveTransaction(this.getDbPath(), id, null, 'DELETE', endpoint).pipe(
            switchMap((data: T | null) => {
              if (data) {
                return new Observable<T>((subscriber) => {
                  subscriber.next(data);
                });
              } else {
                return new Observable<T>((subscriber) => {
                  subscriber.error('Error');
                });
              }
            }),
          );
        }
      }),
    );
  }

  get(id: string): Observable<T | null> {
    const endpoint = `${this.url}/${this.getDbPath()}/${id}`;

    return this.getOnlineStatus().pipe(
      switchMap((online) => {
        if (online) {
          return this.http.get<T>(endpoint).pipe(
            switchMap((response) =>
              this.dataService.setLocalData(this.getDbPath(), id, response).pipe(map(() => response)),
            ),
            catchError((error) => {
              console.log('Error fetching entity:', error);
              return from(this.dataService.getLocalData<T>(this.getDbPath(), id));
            }),
          );
        } else {
          return from(this.dataService.getLocalData<T>(this.getDbPath(), id));
        }
      }),
    );
  }

  getAll(queryOptions: QueryOptions): Observable<T[]> {
    let params = new HttpParams();

    if (queryOptions.limit) {
      params = params.set('limit', queryOptions.limit.toString());
    }
    if (queryOptions.orderBy) {
      params = params.set('orderBy', queryOptions.orderBy);
    }
    if (queryOptions.startAt) {
      params = params.set('startAt', queryOptions.startAt);
    }
    if (queryOptions.startAfter) {
      params = params.set('startAfter', queryOptions.startAfter);
    }
    if (queryOptions.endAt) {
      params = params.set('endAt', queryOptions.endAt);
    }
    if (queryOptions.endBefore) {
      params = params.set('endBefore', queryOptions.endBefore);
    }
    // Handle multiple where conditions
    if (queryOptions.where && queryOptions.where.length > 0) {
      queryOptions.where.forEach((condition, index) => {
        params = params.set(`where[${index}].field`, condition.field);
        params = params.set(`where[${index}].operator`, condition.operator);
        params = params.set(`where[${index}].value`, String(condition.value ?? ''));
      });
    }

    const key = this.generateKeyFromQueryOptions(queryOptions);
    const endpoint = this.generateEndpointFromQueryOptions(queryOptions);

    return this.getOnlineStatus().pipe(
      switchMap((online) => {
        if (online) {
          return this.http.get<T[]>(endpoint, { params }).pipe(
            switchMap((response) =>
              this.dataService.setLocalData(this.getDbPath(), key, response).pipe(map(() => response)),
            ),
            catchError((error) => {
              console.log('Error fetching entities:', error);
              return this.handleOfflineData(key);
            }),
          );
        } else {
          return this.handleOfflineData(key);
        }
      }),
    );
  }

  async wipeData() {
    await this.dataService.wipeAllData().catch((error) => {
      alert('Error wiping data');
    });
  }

  public getUserId(): string | null {
    const user = this.auth.currentUser;
    return user?.uid ?? null;
  }

  private generateKeyFromQueryOptions(queryOptions: any): string {
    return JSON.stringify(queryOptions);
  }

  private handleOfflineData(key: string, error?: any): Observable<T[]> {
    return from(this.dataService.getLocalData<T[]>(this.getDbPath(), key)).pipe(
      switchMap((localData) => {
        if (localData && localData.length > 0) {
          return of(localData);
        } else {
          return this.dataService.getAllLocalData<T>(this.getDbPath());
        }
      }),
      catchError((error) => {
        console.error('Error retrieving local data:', error);
        return of([]);
      }),
    );
  }

  private generateEndpointFromQueryOptions(queryOptions: any): string {
    const params = new URLSearchParams(queryOptions).toString();
    return `${this.url}/${this.getDbPath()}`;
  }

  private getOnlineStatus(): Observable<boolean> {
    return defer(() => from(Capacitor.isNativePlatform() ? Capacitor.getPlatform() : Promise.resolve('web'))).pipe(
      switchMap((platform) => {
        if (platform === 'web') {
          return this.getWebOnlineStatus();
        } else {
          return this.getNativeOnlineStatus();
        }
      }),
      catchError((error) => {
        console.error('Error in getOnlineStatus:', error);
        return of(false); // Return false in case of error to continue the flow
      }),
    );
  }

  private getWebOnlineStatus(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      fetch('https://www.google.com', { method: 'HEAD', mode: 'no-cors' })
        .then(() => {
          subscriber.next(true);
          subscriber.complete();
        })
        .catch((error) => {
          console.error('Fetch failed, offline:', error);
          subscriber.next(false);
          subscriber.complete();
        });
    });
  }

  private getNativeOnlineStatus(): Observable<boolean> {
    return from(Network.getStatus()).pipe(
      map((status) => status.connected),
      catchError((error) => {
        console.error('Error getting network status:', error);
        // Fallback to navigator.onLine as a last resort in case of native error
        return of(navigator.onLine);
      }),
    );
  }

  private logInfo(message: string): void {
    if (isDevMode() || Capacitor.isNativePlatform()) {
    }
  }

  private logError(message: string): void {
    if (isDevMode() || Capacitor.isNativePlatform()) {
      console.error(`[${this.getServiceIdentifier()}] ${message}`);
    }
  }
}
