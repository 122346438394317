import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, catchError, throwError, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { addDoc, collection, Firestore } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastCtrl: ToastController,
    private router: Router,
    private firestore: Firestore,
    private ngZone: NgZone,
    private auth: Auth,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(async (event) => {
        if (event instanceof String) {
          return;
        }
        if (event instanceof HttpResponse) {
          const body = event.body as any;
          if (!body) return;
          if (body.error && body.error.message) {
            await this.presentToast(body);
          }
        }
      }),
      catchError((err) => {
        return this.handleHttpError(err);
      }),
    );
  }

  private handleHttpError(err: any): Observable<never> {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        this.router.navigate(['/login']);
      }
    }
    console.error(err);
    this.presentToast(JSON.stringify(err)).then(() => {
      // Re-throw the error after processing it, so it can be caught by local error handlers
      return throwError(() => err);
    });
    // Re-throw the error immediately for synchronous handling
    return throwError(() => err);
  }

  async presentToast(error: any) {
    const toast = await this.toastCtrl.create({
      message: `Something went wrong, please try again later or contact support.`,
      duration: 20000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();

    const platform = Capacitor.getPlatform();
    // Ensure Firestore operations are conducted inside Angular's zone to maintain proper execution context
    this.ngZone.run(async () => {
      const currentUser = this.auth.currentUser ?? {};
      const errorLog = {
        message: error.message || null,
        stack: error.stack || null,
        timestamp: new Date(),
        platform,
        user: currentUser ?? 'anonymous user',
      };
      const errorsCollection = collection(this.firestore, 'app_errors');

      // // Log the error to Firestore
      await addDoc(errorsCollection, errorLog).catch(console.error);
    });
  }
}
