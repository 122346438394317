import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AuthInterceptor } from './services/interceptors';
import { IdleService } from './services';
import { isUserLoggedInSignal } from './shared';
import { Capacitor } from '@capacitor/core';
import { provideLottieOptions } from 'ngx-lottie';
import { VersionCheckService } from './services/helpers/version-check.service';
import { MapConnectionStore, MapPermissionStore, MapStore, OrganizationStore, UserStore } from './store';
import { environment } from 'src/environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      mode: 'md',
      statusTap: true,
      sanitizerEnabled: true,
      hideCaretOnScroll: true,
      scrollAssist: true,
      hardwareBackButton: false,
    }),
    provideRouter(appRoutes, withComponentInputBinding()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withFetch(), withInterceptorsFromDi()),

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: (service: IdleService) => () => service.startTracking(),
      deps: [IdleService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (versionCheckService: VersionCheckService) => () => {
        versionCheckService.checkAndClearIndexedDB();
        return Promise.resolve(); // Ensure it doesn't block the bootstrap process
      },
      deps: [VersionCheckService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => isUserLoggedInSignal.set(false),
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    OrganizationStore,
    MapStore,
    UserStore,
    MapPermissionStore,
    MapConnectionStore,
  ],
};
